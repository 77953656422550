<template>
  <b-card
    class="companyqrcode-edit-wrapper"
  >
    <!-- form -->
    <b-form id="companyqrcodeForm" class="edit-form mt-2">
      <b-row>
        <b-col md="4">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="部门名称"
            label-for="department_name"
            label-size="sm"
            class="mb-1 required"
          >
            <b-form-input
              id="department_name"
              size="sm"
              v-model="companyqrcode.department_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="部门编码"
            label-for="department_code"
            label-size="sm"
            class="mb-1 required"
          >
            <b-form-input
              id="department_code"
              size="sm"
              v-model="companyqrcode.department_code"
            />
          </b-form-group>
        </b-col>


        <b-col md="4">
          <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="启用"
                  label-for="in_menu"
                  label-size="sm"
                  class="mb-1"
          >
            <b-form-checkbox
                    name="check-button"
                    switch

                    id="in_menu"
                    v-model="companyqrcode.state"
            />
          </b-form-group>
        </b-col>


        <!-- <b-col md="3">
         <b-form-group
           label-cols="3"
           label-cols-lg="3"
           label="收款码(单个)"
           label-for="qrcode_id"
           label-size="sm"
           class="mb-1"
         >
           <b-form-input
             id="qrcode_id"
             size="sm"
             v-model="companyqrcode.qrcode_id"
           />
         </b-form-group>
       </b-col>-->

        <b-col md="12">

          <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="附件"
                  label-for="attachments"
                  label-size="sm"
                  class="mb-1 required"
          >
            <attachment-upload  v-if="companyqrcode.loaded" :theme="'files'"
                                :attachment_id="'attachments'"
                                :id="companyqrcode.qrcode_id"
                                :object_type="'companyqrcode'"
                                :object_id="companyqrcode.id"
                                @change="onUploaded"
            />
          </b-form-group>
        </b-col>


                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import companyqrcodeStore from './companyqrcodeStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    AttachmentUpload
  },
  data() {
    return {
      id: ref(0),
      companyqrcode: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('companyqrcode')) store.registerModule('companyqrcode', companyqrcodeStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('companyqrcode')) store.unregisterModule('companyqrcode')
    })

    const edit = function() {
      store.dispatch('companyqrcode/edit', {id: this.id}).then(res => {
        this.companyqrcode = res.data.data
      })
    }

    const view = function() {
      store.dispatch('companyqrcode/view', {id: this.id}).then(res => {
        this.companyqrcode = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      let codeId = this.companyqrcode.qrcode_id
      let departmentName = this.companyqrcode.department_name
      let departmentCode = this.companyqrcode.department_code
      if(isNaN(codeId)){
        toast.error("收款码是唯一的")
        return
      }
      if(departmentCode===""||departmentName==""||codeId===""){
        toast.error("必填项未填！")
        return
      }

      store.dispatch('companyqrcode/save', this.companyqrcode).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-companyqrcode-list'});
      })
    }

    const onUploaded = function (id, attachment, result) {
      this.companyqrcode.qrcode_id = result
    }

    return {
      edit,
      view,
      cancel,
      save,
      
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      onUploaded,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>